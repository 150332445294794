import React from 'react';
import Select, {IOption} from './Select';
import {
  AppBar,
  Button,
  ButtonGroup,
  Checkbox,
  FormControlLabel,
  Grid,
  Icon,
  IconButton,
  Toolbar
} from '@material-ui/core';
import {NextComponentToRender, TComponents} from '../container';
import PrimaryHeader from './PrimaryHeader';

const Parameters: React.FC<IParametersProps> = (props) => {
  if (!props.paramsOpen) {
    return <ParamsClosed openParams={props.toggleParams}/>;
  }
  return (
    <form
      onSubmit={props.getData}
      className="parameter-container"
      style={{flexGrow: 1}}
    >
      <Grid container={true} justify={'space-evenly'}>
        <Grid item={true} lg={3} md={3} sm={6} xs={12}>
          <Select
            label="Construction Manager"
            focused={true}
            url="cmLookConstructionManagers"
            multi={true}
            run={props.run}
            value={props.cm}
            onSelect={props.selectCm}
          />
          <Select
            label="Document Control"
            url="cmLookDocumentControl"
            multi={true}
            run={props.run}
            value={props.dc}
            onSelect={props.selectDc}
          />
        </Grid>
        <Grid item={true} lg={3} md={3} sm={6} xs={12}>
          <Select
            label="Campus"
            url="cmLookCampuses"
            multi={true}
            run={props.run}
            value={props.campus}
            orderBy={'label'}
            onSelect={props.selectCampus}
          />
          <Select
            label="Project"
            url="cmLookProject"
            multi={true}
            run={props.run}
            value={props.projectNumber}
            orderBy={'label'}
            onSelect={props.selectProject}
          />
        </Grid>
        <Grid item={true} lg={3} md={3} sm={6} xs={12}>
          <Select
            label="Project Manager"
            url="cmLookProjectManagers"
            multi={true}
            run={props.run}
            value={props.pm}
            onSelect={props.selectPm}
          />
          <Select
            label="Inspector"
            url="cmLookInspector"
            multi={true}
            run={props.run}
            value={props.inspector}
            onSelect={props.selectInspector}
          />
        </Grid>
        <Grid item={true} lg={3} md={3} sm={6} xs={12}>
          <Select
            label="Project Type"
            url="cmLookProjectType"
            multi={true}
            run={props.run}
            value={props.projectType}
            onSelect={props.selectProjectType}
          />
          <Select
            label="Status"
            url="unifierProjectStatus"
            multi={true}
            run={props.run}
            value={props.status}
            onSelect={props.selectStatus}
            optionAdapter={statusAdapter}
            orderBy="name"
          />
        </Grid>
        <Grid item={true} lg={12} md={12} sm={12} xs={12}>
          <ButtonGroup variant="contained" color="primary">
            <Button
              variant="contained"
              type="submit"
              disabled={props.loading}
              color="primary">
              View
            </Button>
            <Button
              variant="contained"
              disabled={!props.touched || props.loading}
              type="reset"
              onClick={props.resetForm}
              color="secondary"
            >
              Reset
            </Button></ButtonGroup>
          &nbsp;
          <FormControlLabel
            control={<Checkbox checked={props.showColumns} onChange={props.toggleColumns}/>}
            label="Show Extra Columns"
          />
          &nbsp;
          <FormControlLabel
            control={<Checkbox checked={props.showTechnologyProjects} onChange={props.toggleShowTech}/>}
            label="Include Technology Projects"
          />
          <ButtonGroup variant="contained" color="primary">
            <Button onClick={props.toggleParams}>
              Hide Search
            </Button>
            <Button onClick={() => props.selectComponent(NextComponentToRender[props.component])}>
              View {NextComponentToRender[props.component]}
            </Button>
            <Button
              onClick={props.exportToCsv}
            >
              Export to CSV
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
    </form>
  );
};

export default Parameters;

interface IParametersProps {
  cm?: IOption[];
  dc?: IOption[];
  campus?: IOption[];
  projectNumber?: IOption[];
  pm?: IOption[];
  inspector?: IOption[];
  projectType?: IOption[];
  status?: IOption[];
  loading: boolean;
  touched: boolean;
  run: boolean;
  showColumns: boolean;
  paramsOpen: boolean;
  showTechnologyProjects: boolean;
  component: TComponents;
  toggleShowComplete(): void;
  toggleShowTech(): void;
  toggleParams(): void;
  toggleColumns(): void;
  getData(): void;
  selectComponent(component: TComponents): void;
  selectProjectType(data: IOption[]): void;
  selectStatus(data: IOption[]): void;
  selectCm(data: IOption[]): void;
  selectDc(data: IOption[]): void;
  selectProject(data: IOption[]): void;
  selectInspector(data: IOption[]): void;
  selectCampus(data: IOption[]): void;
  selectPm(data: IOption[]): void;
  resetForm(): void;
  exportToCsv(): void;
}

const ParamsClosed = (props: IParamsClosedProps): JSX.Element => {
  return (
    <AppBar position="static" color="default">
      <Toolbar>
        <PrimaryHeader>Search...</PrimaryHeader>
        <IconButton onClick={props.openParams}>
          <Icon>add_circle</Icon>
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

interface IParamsClosedProps {
  openParams(): void;
}

function statusAdapter(status: { id: number; createDate: string; description: string; name: string; }): IOption {
  return {
    value: status.name,
    label: status.name
  };
}