
export function toNum(str: string){
  let val = parseFloat(str);
  if(isNaN(val)){val = 0;}
  return val;
}

export function calcPct(pct: any){
  // 8/9/2017 - KS
  // Pete request hide progress shading on task bars.
  // Solution to set each activity to 100%
  return 1;
  /*if(!pct || pct < 0){return 0;}
  return pct / 100;*/
}

export function log() {
  // @ts-ignore -- allow check for debug
  if(_searchToObject(window.location.search).debug) {
    console.log(...arguments);
  }
}

function _searchToObject(search: string) {
  // remove ? from beginning of string
  // ====================================
  return search.substring(1)
  // Split key-value pairs
  // ====================================
    .split('&')
    // reduce through and decode
    // ====================================
    .reduce(function(result: any, value: string) {
      var parts = value.split('=');
      if (parts[0]) { result[decodeURIComponent(parts[0])] = decodeURIComponent(parts[1]); }
      return result;
    }, {})
}

// return in mm/dd/yyyy format
export const fixDate = (str?: string): string => {
  if(!str) {return '';}
  const d = new Date(str);
  return `${d.getMonth()+1}/${d.getDate()}/${d.getFullYear()}`;
};