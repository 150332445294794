/* eslint-disable */
import React from 'react';
import accounting from 'accounting';
import {IProjectAssignment} from '../utils/api';
import {AgGridReact} from 'ag-grid-react';
import columnDefinitions from './DataTable/ColumnDefinition';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';

accounting.settings.currency.precision = 0;

const DataTable: React.FC<IDataTable> = (props) => {
  const cols = columnDefinitions(props.showColumns);
  return (
    <div style={{height: '80vh', width: '100vw'}} className="ag-theme-balham">
      <AgGridReact
        columnDefs={cols}
        rowData={props.data}
        rowHeight={90}
        defaultColDef={{resizable: true}}
        alwaysShowVerticalScroll={true}
      />
    </div>
  );
};

export default DataTable;

export interface IDataTableProps {
  data: IProjectAssignment[];
  showColumns: boolean;
}

interface IDataTable extends IDataTableProps {
}
