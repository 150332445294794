import React from 'react';
import ReactDOM from 'react-dom';
import {ICellRendererComp, ICellRendererParams} from 'ag-grid-community';
import {IProjectAssignment, TCMPAStatus} from '../../utils/api';
import Complete from '@material-ui/icons/Check';
import Terminated from '@material-ui/icons/Block';

export default class CMPAFormCell implements ICellRendererComp {
  // @ts-ignore tsc imad that private field is set in init method instead of constructor
  private data: IProjectAssignment;
  // @ts-ignore
  private elem: HTMLElement;
  init(params: ICellRendererParams): void {
    this.data = params.data;
    this.elem = document.createElement('div');
    return;
  }

  getGui(): HTMLElement {
    const cmPA = this.data.cmPA;
    ReactDOM.render((function () {
      return getPAIcon(cmPA);
    })(), this.elem);
    return this.elem;
  }

  refresh(params: any): boolean {
    return false;
  }

}

function getPAIcon(status?: TCMPAStatus): any {
  switch (status) {
    case 'Complete':
      return <span title="Complete" style={{color: 'green'}}><Complete/></span>;
    case 'In_Progress':
      return <span title="In Progress" style={{color: 'lightgreen'}}><Complete/></span>;
    case 'Terminated':
      return <span title="Terminated" style={{color: 'red'}}><Terminated/></span>;
    default:
      return '';
  }
}