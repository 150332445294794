import React, {PureComponent} from 'react';
import Spinner from 'react-spinkit';
import {IOption} from '../components/Select';
import Parameters from '../components/Parameters';
import {log} from '../utils/utils';
import errorReport from '../utils/errorReport';
import {getProjectAssignments, IODataParameters, IProjectAssignment} from '../utils/api';
import DataTable, {IDataTableProps} from '../components/DataTable';
import DhxGantt from '../components/DHXGantt';
import {Grid} from '@material-ui/core';
import BodyText from '../components/BodyText';
import SecondaryHeader from '../components/SecondaryHeader';
import PrimaryHeader from '../components/PrimaryHeader';
import {downloadCsvFile} from '../utils/csvUtils';
import {Canceler} from 'axios';
import Button from '@material-ui/core/Button';

const CANCEL_MESSAGE = 'Search Cancelled';

const initialState: IParameterState = {
  touched: false,
  run: false,
  loading: false,
  showColumns: true,
  showParams: true,
  showCompleteProjects: false,
  showTechnologyProjects: false,
  projectType: [],
  status: [{label: 'Active', value: 'Active'}],
  cm: [],
  dc: [],
  projectNumber: [],
  inspector: [],
  campus: [],
  pm: [],
  error: '',
  data: [],
  component: 'table'
};

const ComponentToRender = {
  table: DataTable,
  gantt: DhxGantt
};

export const NextComponentToRender: { [prop in TComponents]: TComponents } = {
  'table': 'gantt',
  'gantt': 'table'
};

export type TCols = 'cm'|'dc'|'projectNumber'|'inspector'|'campus'|'campusName'|'pm'|'projectName'|'fundingSource'|'cmPA'|'startDate'|'finishDate'|'docControl'|'projectType'|'projectTypeName'|'currentBudget'| 'actualToDate' |'constructionAmount'|'fundPct'|'isTechnology'|'status';

type TSelectOption = (data: IOption[]) => void;

export default class App extends PureComponent<IParameterProps, IParameterState> {
  private cancelGetData?: Canceler;
  private selectCm: TSelectOption;
  private selectDc: TSelectOption;
  private selectProject: TSelectOption;
  private selectInspector: TSelectOption;
  private selectCampus: TSelectOption;
  private selectPm: TSelectOption;
  private selectProjectType: TSelectOption;
  private selectStatus: TSelectOption;
  constructor(props: IParameterProps) {
    super(props);
    this.state = initialState;
    this.selectCm = this.onSelect.bind(this, 'cm');
    this.selectDc = this.onSelect.bind(this, 'dc');
    this.selectProject = this.onSelect.bind(this, 'projectNumber');
    this.selectInspector = this.onSelect.bind(this, 'inspector');
    this.selectCampus = this.onSelect.bind(this, 'campus');
    this.selectPm = this.onSelect.bind(this, 'pm');
    this.selectProjectType = this.onSelect.bind(this, 'projectType');
    this.selectStatus = this.onSelect.bind(this, 'status')
  }

  /* Class Method Pattern to bind scope and curry first arg in render method */
  onSelect(prop: TCols, data: IOption[]) {
    // react-select clear filters will save a NULL instead of empty array.
    data = data || [];
    // @ts-ignore -- TSC doesn't know that TCols isn't complete list of state
    this.setState({
      [prop]: data,
      touched: true
    });
  }

  componentDidMount() {
    this.getData();
  }

  getData = (e?: any) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    this.setState({
      touched: true,
      loading: true,
      data: initialState.data,
      error: ''
    }, () => this.getGanttData(this.createFilter()))
  };

  resetForm = (e?: any) => {
    if (e.preventDefault) {
      e.preventDefault();
    }
    this.setState(initialState);
  };

  createFilter = () => {
    const {
      cm, pm, dc, projectNumber, inspector, showTechnologyProjects,
      campus, projectType, status,
    } = this.state;
    // set default filter
    // const filters = [`year(finishDate) Ge ${year}`]; -- no MS Project Schedule Data
    const filters = [];
    // create initial filters
    if (!showTechnologyProjects) {
      filters.push('isTechnology eq false')
    }
    // for multiple selections: create each selection as OR
    if (dc.length) {
      filters.push(dc.map(filterItem.bind(null, 'docControl')).join(' or '));
    }
    if (projectNumber.length) {
      filters.push(projectNumber.map(filterItem.bind(null, 'projectNumber')).join(' or '));
    }
    if (cm.length) {
      filters.push(cm.map(filterItem.bind(null, 'cm')).join(' or '));
    }
    if (pm.length) {
      filters.push(pm.map(filterItem.bind(null, 'pm')).join(' or '));
    }
    if (inspector.length) {
      filters.push(inspector.map(filterItem.bind(null, 'inspector')).join(' or '));
    }
    if (campus.length) {
      filters.push(campus.map(filterItem.bind(null, 'campusName')).join(' or '));
    }
    if (projectType.length) {
      filters.push(projectType.map(filterItem.bind(null, 'projectTypeName')).join(' or '));
    }
    if (status.length) {
      filters.push(status.map((status) => `status eq "${status.value}"`).join(' or '));
    }
    // join all filters as AND operation
    const filterParams = `(${filters.join(') and (')})`;
    // @ts-ignore
    log(filterParams);

    return {
      $filter: filterParams
    };

    function filterItem(key: TCols, item: IOption) {
      // check if projectNumber
      if (key === 'projectNumber' && item.__isNew__) {
        return `contains(projectName,"${item.value}")`
      }
      return `contains(${key},"${item.value}")`
    }
  };

  getGanttData = (params: IODataParameters) => {
    if (this.cancelGetData) {
      this.cancelGetData();
    }
    const { request, cancel } = getProjectAssignments(params);

    this.cancelGetData = cancel;

      return request.then((ganttData) => {
        this.setState({data: ganttData, run: true, loading: false});
      })
      .catch((error) => {
        if(error.message === CANCEL_MESSAGE) {
          return this.setState({loading: false, error: '', run: false});
        }
        var message = error.message, code, stack;
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          message = error.response.responseText;
          code = error.response.status;
          stack = error.response.data;
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest
          code = error.request.status;
          message = error.message;
          stack = error.request.responseText;
        }
        errorReport({code, message, stack});
        this.setState({error: error.message, loading: false});
      });
  };

  cancelSearch = () => {
    if(this.cancelGetData) {
      this.cancelGetData(CANCEL_MESSAGE);
    }
  }

  selectComponent = (component: TComponents) => this.setState({component});

  // @ts-ignore -- ignore only partially updating state property
  toggleOption = (option: TTogglableState) => this.setState({[option]: !this.state[option]});

  toggleColumns = () => this.toggleOption('showColumns');

  toggleParams = () => this.toggleOption('showParams');

  toggleShowComplete = () => this.toggleOption('showCompleteProjects');

  toggleShowTech = () => this.toggleOption('showTechnologyProjects');

  exportToCsv = () => {
    // @ts-ignore
    const {data} = this.state;
    const headers: Array<keyof IProjectAssignment> = ['projectName','projectNumber','fundingSource','status','currentBudget','actualToDate','constructionAmount','cmPA','inspector','startDate','finishDate','cm','cmAsst','docControl','scheduler'];
    const header = headers.join(',');
    let retStr: string = `${header}\n`;

    data.forEach((row) => retStr += getCsvRow(row));

    downloadCsvFile(retStr);

    function getCsvRow(row: IProjectAssignment): string {
      let str: string = '';
      headers.forEach((prop, i, array) => {
        let val = row[prop as keyof IProjectAssignment]  || '';
        str += `${String(val).replace(/,/g, ' ')}`;
        // should I add a trailing comma or new line?
        str += i !== array.length - 1 ? ',' : '\n';
      });
      return str;
    }
  };

  render() {
    const {
      cm, pm, dc, projectNumber, projectType, inspector, campus, data, component, showParams,
      showTechnologyProjects, loading, run, touched, showColumns, status
    } = this.state;
    return (
      <Grid container={true}>
        <Parameters
         campus={campus}
         cm={cm}
         pm={pm}
         dc={dc}
         projectType={projectType}
         status={status}
         run={run}
         projectNumber={projectNumber}
         inspector={inspector}
         loading={loading}
         touched={touched}
         showColumns={showColumns}
         paramsOpen={showParams}
         toggleParams={this.toggleParams}
         toggleColumns={this.toggleColumns}
         toggleShowComplete={this.toggleShowComplete}
         toggleShowTech={this.toggleShowTech}
         showTechnologyProjects={showTechnologyProjects}
         component={component}
         selectComponent={this.selectComponent}
         selectCm={this.selectCm}
         selectDc={this.selectDc}
         selectProject={this.selectProject}
         selectInspector={this.selectInspector}
         selectCampus={this.selectCampus}
         selectPm={this.selectPm}
         selectProjectType={this.selectProjectType}
         selectStatus={this.selectStatus}
         getData={this.getData}
         resetForm={this.resetForm}
         exportToCsv={this.exportToCsv}
        />
        <Grid container={true} className={`gantt-container ${!showParams && 'lg-container'}`}>
          <Grid item={true} lg={12} md={12} sm={12} xs={12}>
            <PrimaryHeader color="error">{this.state.error}</PrimaryHeader>
            <RenderDetailComponent
              run={run}
              loading={loading}
              data={data}
              component={component}
              showColumns={showColumns}
              cancel={this.cancelSearch}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

interface IRenderDetailComponentProps extends Pick<IParameterState,'run'|'loading'|'component'>, IDataTableProps {
  cancel(): any;
}
const RenderDetailComponent = ({run, loading, data = [], component, cancel, ...rest}: IRenderDetailComponentProps) => {
  if (loading) {
    return (
      <Grid
        container={true}
        direction="column"
        alignItems="center"
        justify="space-between"
        spacing={6}
        style={{marginTop: '20vh'}}
      >
        <Grid
          item={true}
          lg={12}
        >
          <Spinner name="ball-scale-ripple-multiple" style={{padding: '1rem'}} />
        </Grid>
        <Grid
          item={true}
          lg={12}
        >
          <Button color="secondary" variant="contained" onClick={cancel}>
            Cancel
          </Button>
        </Grid>
      </Grid>
    );
  }
  if (!data.length && run) {
    return (
      <Grid
        container={true}
        justify="center"
      >
        <SecondaryHeader>No Data</SecondaryHeader>
        <br/>
        <BodyText>No data using arguments, please retry.</BodyText>
      </Grid>
    );
  }
  const Components = ComponentToRender[component];
  return <Components data={data} {...rest} />;
};

interface IParameterProps {

}

interface IParameterState {
  touched: boolean;
  run: boolean;
  loading: boolean;
  showColumns: boolean;
  showParams: boolean;
  showCompleteProjects: boolean;
  showTechnologyProjects: boolean;
  component: TComponents;
  cm: IOption[];
  dc: IOption[];
  projectType: IOption[];
  status: IOption[];
  projectNumber: IOption[];
  inspector: IOption[];
  campus: IOption[];
  pm: IOption[];
  error: string;
  data: IProjectAssignment[];
}

export type TComponents = 'gantt' | 'table';
type TTogglableState = 'showColumns' | 'showParams' | 'showCompleteProjects' | 'showTechnologyProjects';
