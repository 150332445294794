/**
 * Created by Kevin on 4/5/2017.
 */
window.loaded = false;
(function(gantt){
  if (window.loaded) {
    return;
  }
  window.loaded = true;
  gantt._show_tooltip = function(text, pos) {
    if (gantt.config.touch && !gantt.config.touch_tooltip) return;

    var tip = this._create_tooltip();

    tip.innerHTML = text;
    gantt.$container.appendChild(tip);

    var width = tip.offsetWidth + 20;
    var height = tip.offsetHeight + 40;
    var max_height = window.outerHeight;
    var max_width = window.outerWidth;
    var scroll = this.getScrollState();

    pos.x -= scroll.x;
    pos.y -= scroll.y;

    var mouse_pos = {
      x: pos.x,
      y: pos.y
    };

    if(pos.x>0){
      pos.x += (gantt.config.tooltip_offset_x*1 || 0);
      pos.x += gantt.config.grid_width;
      pos.y += (gantt.config.tooltip_offset_y*1 || 0);

      pos.y = Math.min(pos.y, max_height - height);
      pos.x = Math.min(pos.x, max_width - width);

      if (gantt._is_cursor_under_tooltip(mouse_pos, {pos: pos, width: width, height: height})) {
        if((mouse_pos.x+width) > (max_width )) pos.x = mouse_pos.x - (width - 20) - (gantt.config.tooltip_offset_x*1 || 0);
        if((mouse_pos.y+height) > (max_height)) pos.y = mouse_pos.y - (height - 40) - (gantt.config.tooltip_offset_y*1 || 0);
      }
    }else {
      pos.x = gantt.config.grid_width;
    }

    tip.style.left = pos.x + "px";
    tip.style.top  = pos.y + "px";
  };

})(window.gantt);
// ====================================
// === Fix Task Text on Small Tasks ===
// ====================================
(function(gantt){
  var templates = gantt.templates;
  gantt.config.font_width_ratio = 7;

  templates.task_text = function taskTextTemplate(start, end, task) {
    if (getTaskFitValue(task) === "center") {
      return task.text;
    }
    return "";
  };

  templates.leftside_text = function leftSideTextTemplate(start, end, task) {
    if (getTaskFitValue(task) === "left") {
      return task.text;
    }
    return "";
  };

  templates.rightside_text = function rightSideTextTemplate(start, end, task) {
    if (getTaskFitValue(task) === "right") {
      return task.text;
    }
    return "";
  };

  function getTaskFitValue(task){
    var taskStartPos = gantt.posFromDate(task.start_date),
        taskEndPos = gantt.posFromDate(task.end_date);

    var width = taskEndPos - taskStartPos;
    var textWidth = (task.text || "").length * gantt.config.font_width_ratio;

    if(width < textWidth){
      var ganttLastDate = gantt.getState().max_date;
      var ganttEndPos = gantt.posFromDate(ganttLastDate);
      if(ganttEndPos - taskEndPos < textWidth){
        return "left"
      }
      else {
        return "right"
      }
    }
    else {
      return "center";
    }
  }

})(window.gantt);