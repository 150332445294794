import React from 'react';
import ReactDOM from 'react-dom';
import {ICellRendererComp, ICellRendererParams} from 'ag-grid-community';
import {IProjectAssignment} from '../../utils/api';

// @ts-ignore
const {goToProject} = window.unifierExtensions;
export default class ProjectInfoCell implements ICellRendererComp {
  // @ts-ignore tsc mad that private field gets set in init method, not constructor
  private data: IProjectAssignment;
  // @ts-ignore tsc mad that private field gets set in init method, not constructor
  private elem: HTMLElement;

  init(params: ICellRendererParams): void {
    this.data = params.data;
    this.elem = document.createElement('div');
    return;
  }

  getGui(): HTMLElement {
    ReactDOM.render((()=>{
      return (
        <>
          <a href="#" onClick={() => goToProject(this.data.pid)}>
            <strong>{this.data.projectName}</strong><br/>
          </a>
          {this.data.projectNumber}<br/>
          Time Card Funding Source: {this.data.fundingSource}
        </>
      );
    })(), this.elem);
    return this.elem;
  }

  refresh(params: any): boolean {
    return false;
  }

}