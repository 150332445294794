/*eslint-disable*/
import React, {PureComponent} from 'react'
import 'dhtmlx-gantt'
import 'dhtmlx-gantt/codebase/ext/dhtmlxgantt_marker'
import 'dhtmlx-gantt/codebase/dhtmlxgantt.css'
const twin: any = window;
const gantt = twin.gantt;
// add gantt customizations
require('../utils/ganttUtils');
import {IProjectAssignment} from '../utils/api';
import {fixDate} from '../utils/utils';

export default class DHXGantt extends PureComponent<IGanttProps,any> {
  private startDateSortDirection: boolean;
  private finishDateSortDirection: boolean;

  constructor(props: IGanttProps){
    super(props);
    this.startDateSortDirection = false;
    this.finishDateSortDirection = false;
  }

  componentDidMount() {
    const {data} = this.props;
    this.configureGantt(gantt, 'ganttHere');
    this.renderData(data);
  }

  componentWillReceiveProps({data}: IGanttProps) {
    // invoked when component is receiving props, not for initial 'render'
    this.renderData(data)
  }

  renderData = (data:IProjectAssignment[])=>{
    gantt.clearAll();
    this.addTodayMarker();
    const d = data.filter((r) => (r.startDate && r.finishDate)).map((row) => {
      if(row.startDate && row.finishDate) {
        // @ts-ignore
        row.start_date = row.startDate = fixDate(row.startDate).split('/').join('-');
        // @ts-ignore
        row.end_date = row.finishDate = fixDate(row.finishDate).split('/').join('-');
        // @ts-ignore
        row.text = row.projectName;
      }
      return row;
    });
    gantt.parse({data: d,links:[]},'json');
  };

  addTodayMarker = () => {
    const dateToStr = gantt.date.date_to_str(gantt.config.task_date),
          today     = new Date();
    gantt.addMarker({
      start_date: today,
      text: 'Today',
      title: 'Today: ' + dateToStr(today)
    });
  };

  onSort = (column: TSortCols ) => (a: IProjectAssignment, b: IProjectAssignment) => {
    const directions: {[prop in TSortCols ]: boolean;} = {
      finishDate: this.finishDateSortDirection,
      startDate: this.startDateSortDirection
    };
    // convert date values to integer to sort. IProjectAssignment date types are sometimes null,
    // but null values are filtered out when they are in the gantt view, so coercion is safe.
    const aVal = toDate(a[column] as string);
    const bVal = toDate(b[column] as string);

    // sort function based on sort direction
    directions[column] = !directions[column];
    if(directions[column]) {
      return aVal > bVal ? 1 : (aVal < bVal ? -1 : 0);
    } else {
      return aVal > bVal ? -1 : (aVal < bVal ? 1 : 0);
    }
  };

  configureGantt = (gantt: any, node: string)=>{
    const config = gantt.config,
      rowHeight = 25;
    config.autosize = 'y';
    config.grid_width = 1250;
    config.select_task = false;
    config.drag_progress = false;
    config.drag_move = false;
    config.drag_resize = false;
    config.show_links = false;
    config.task_height = 18;
    config.row_height = rowHeight;
    config.min_column_width = 30;
    config.details_on_dblclick = false;
    config.xml_date = '%m-%d-%Y';
    config.scale_unit = 'year';
    config.date_scale = '%Y';
    config.sort = true;
    config.columns = [
      {name: 'projectName', label: 'Project', min_width:80, width: 200, align: 'left', tree: false, resize: true, sort: true},
      {name: 'startDate', label: 'Start Date', min_width: 90, align: 'right', resize: true, sort: this.onSort('startDate')},
      {name: 'finishDate', label:'End Date', min_width: 90, align: 'right', resize: true, sort: this.onSort('finishDate')}
    ];
    gantt.init(node);
  };

  render() {
    return (
      <div style={{height: '35rem',width:'100%'}} id="ganttHere">&nbsp;</div>
    );
  }
}

interface IGanttProps {
  data: IProjectAssignment[];
}

type TSortCols = 'startDate' | 'finishDate';

/**
 * Convert MM/DD/YYYY date string to ISO Date number
 * @param str
 */
function toDate(str: string): number {
  return new Date(str).valueOf();
}