import Ajax, {getCancelConfig, ICancellable} from './Ajax';

export const getProjectAssignments = (params: IODataParameters): ICancellable<Promise<IProjectAssignment[]>> => {
  const {cancel, cancelToken} = getCancelConfig();

  return {
    request: Ajax.get(`unifierProjectAssignments`, {params, cancelToken})
      .then(({data: {value}}) => (value)),
    cancel
  }
};

export interface IProjectAssignment {
  actualToDate: number;
  campusName: string;
  cm?: string;
  cmAsst?: string;
  cmPA?: TCMPAStatus;
  constructionAmount: number;
  currentBudget: number;
  currentProjectPhase?: string;
  docControl?: string;
  finishDate?: string;
  finishSource?: TDateSource;
  fundPct?: string;
  fundingSource: string;
  gc?: string;
  inspector?: string;
  isNew: boolean;
  isTechnology: boolean;
  pid: number;
  pm?: string;
  projectName: string;
  projectNumber: string;
  projectType?: string;
  projectTypeName?: string;
  scheduler?: string;
  startDate?: string;
  startSource?: TDateSource;
  startYear?: number;
  status: string;
}

export type TCMPAStatus = 'Complete' | 'In_Progress' | 'Terminated';
export type TDateSource = 'CM' | 'FAST_CIP' | 'PM';
export interface IODataParameters {
  $filter?: string;
  $select?: string;
  $orderby?: string;
  $top?: number;
  $skip?: number;
  $count?: boolean;
}