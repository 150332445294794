import React from 'react';
import {Typography, TypographyProps} from '@material-ui/core';

const SecondaryHeader: React.FC<ISecondaryHeaderProps> = (props) => {
  const {children, ...rest} = props;
  return (
    <Typography variant="h3" {...rest}>{children}</Typography>
  );
};

export default SecondaryHeader;

interface ISecondaryHeaderProps extends Omit<TypographyProps, 'variant'> {
}