import React from 'react';
import Parameters from './container/';

function System() {
  return (
    <Parameters/>
  );
}

export default System;
