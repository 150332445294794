import React from 'react';
import {Typography, TypographyProps} from '@material-ui/core';

const BodyText: React.FC<IBodyTextProps> = (props) => {
  const {children, ...rest} = props;
  return (
    <Typography variant="body1" {...rest}>{children}</Typography>
  );
};

export default BodyText;

interface IBodyTextProps extends Omit<TypographyProps, 'variant'> {
}