import React, {PureComponent} from 'react'
import CreatableSelect from 'react-select/creatable'
import makeAnimated from 'react-select/animated'
import Ajax from '../utils/Ajax'
import {IODataParameters} from '../utils/api';

const initialState: ISelectState = {
  opts: [],
  isLoading: false
};

class Select extends PureComponent<ISelectProps, ISelectState> {
  constructor(props: ISelectProps) {
    super(props);
    this.state = initialState;
  }

  componentDidUpdate(prevProps: ISelectProps, prevState: ISelectState) {
    // invoked immediately after DOM updates, not for initial 'render'
    // get options AFTER initial data load
    if (!prevProps.run && this.props.run) {
      this.getOptions();
    }
  }

  getOptions = () => {
    if (this.state.opts.length === 0 && !this.state.isLoading) {
      this.setState({isLoading: true}, () => {
        const params:IODataParameters = {
          $orderby: `${this.props.orderBy || 'value'}`
        };
        return Ajax.get(this.props.url,{params})
          .then((res) => {
            let opts: IOption[];
            if (this.props.optionAdapter) {
              opts = res.data.value.map(this.props.optionAdapter);
            } else {
              opts = res.data.value;
            }
            this.setState({opts, isLoading: false})
          })
      })
    }
  };

  formatCreateLabel = (value: string)=>{
    return `Contains ${value}`
  };

  render() {
    const {opts, isLoading} = this.state;
    const {onSelect, label, value, multi = false, focused = false} = this.props;
    return (
      <div className="form-horizontal">
        <div className="form-group">
          <label className="col-lg-4 col-md-4 col-sm-6 col-xs-6">{label}</label>
          <div className="col-lg-8 col-md-8 col-sm-6 col-xs-6">
            <CreatableSelect
              allowCreateWhileLoading={true}
              formatCreateLabel={this.formatCreateLabel}
              createOptionPosition="first"
              isLoading={isLoading}
              // @ts-ignore -- doesn't like IOption[]
              options={opts}
              // @ts-ignore
              getOptionValue={(opt) => opt.value}
              // @ts-ignore
              getOptionLabel={(opt) => opt.label}
              onMenuOpen={this.getOptions}
              components={makeAnimated()}
              // @ts-ignore
              value={value}
              isClearable={true}
              isMulti={multi}
              closeMenuOnSelect={!multi}
              onChange={onSelect}
              placeholder="All"
              autofocus={focused}
              menuPortalTarget={document.body}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Select

export interface IOption {
  label: string;
  value: string;
  __isNew__?: boolean;
}

interface ISelectProps {
  label: string;
  url: string;
  value?: IOption[];
  focused?: boolean;
  orderBy?: string;
  multi?: boolean;
  run: boolean;
  optionAdapter?(data: any) : IOption;
  onSelect(data: any): void;
}
interface ISelectState {
  opts: IOption[];
  isLoading: boolean;
}