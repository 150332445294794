import {CellClassParams, ColDef, RowNode, ValueFormatterParams} from 'ag-grid-community';
import ProjectInfoCell from './ProjectInfoCell';
import accounting from 'accounting';
import CMPAFormCell from './CMPAFormCell';
import {fixDate} from '../../utils/utils';
import {TDateSource} from '../../utils/api';

accounting.settings.currency.precision = 0;

const {width} = document.getElementsByTagName('body')[0].getBoundingClientRect();

// dynamic column widths
// adjust column width (LG|MD|SM) in PX such that
// width of screen subtracted by the SUM of column width (LG|MD|SM) * Number of Columns using that width equals 20
// example: 2,400px screen width - ((LG * 3) + (MD * 7) + (SM * 4)) = 20;
// then take column width size in PX / screen width to get the percentage.
// % of screen width
const XL = 0.1870 * width;
const LG = 0.077083 * width;
const MD = 0.0625 * width;
const SM = 0.05 * width;

const LeftColumns: ColDef[] = [{
  headerName: `Project
(Click to Open in Unifier)`,
  cellRenderer: ProjectInfoCell,
  width: XL,
  headerTooltip: 'Project Information per Unifier',
  sortable: true
}];

const ToggleableColumns: ColDef[] = [{
  headerName: 'Project Status',
  headerTooltip: 'Status per CM Schedule',
  field: 'status',
  width: SM,
  sortable: true
}, {
  headerName: 'Current Total Budget',
  headerTooltip: 'Current Total Budget',
  field: 'currentBudget',
  valueFormatter: currencyFormatter,
  width: MD,
  type: 'numericColumn',
  sortable: true
}, {
  headerName: 'Actual Cost to Date',
  headerTooltip: 'Actual Cost to Date',
  field: 'actualToDate',
  valueFormatter: currencyFormatter,
  width: MD,
  type: 'numericColumn',
  sortable: true
}, {
  headerName: 'Estimated Cost at Completion',
  headerTooltip: 'Estimated Cost at Completion',
  field: 'constructionAmount',
  valueFormatter: currencyFormatter,
  width: MD,
  type: 'numericColumn',
  sortable: true

}];

const RightColumns: ColDef[] = [{
  headerName: 'CM PA Form',
  headerTooltip: 'CM PA Form Status (blank= no PA Form)',
  width: SM,
  cellRenderer: CMPAFormCell,
  sortable: true
},{
  headerName: 'Construction Start',
  headerTooltip: 'Data Source: Blue = CM Dept; Orange = PM Dept; Black = Program Controls',
  width: LG,
  field: 'startDate',
  type: 'numericColumn',
  cellClass(p: CellClassParams) {return `text-right ${DateClassMap[p.data.startSource]}`},
  valueFormatter: params => fixDate(params.value),
  comparator: dateComparator,
  sortable: true
},{
  headerName: 'Construction Finish',
  headerTooltip: 'Data Source: Blue = CM Dept; Orange = PM Dept; Black = Program Controls',
  width: LG,
  field: 'finishDate',
  type: 'numericColumn',
  cellClass(p: CellClassParams){ return `text-right ${DateClassMap[p.data.finishSource]}`},
  valueFormatter: params => fixDate(params.value),
  comparator: dateComparator,
  sortable: true
},{
  headerName: 'CM(s)',
  headerTooltip: 'CM(s) per Unifier Project Details Information',
  field: 'cm',
  width: LG
},{
  headerName: 'Asst CM(s)',
  headerTooltip: 'Assistant CM(s) per Unifier Project Details Information',
  field: 'cmAsst',
  width: LG
},{
  headerName: 'Inspector(s)',
  headerTooltip: 'Inspectors & Assistant Project Inspector(s) per Unifier Project Details Information',
  field: 'inspector',
  width: LG
},{
  headerName: 'Document Control',
  headerTooltip: 'Document Control per Unifier Project Details Information',
  field: 'docControl',
  width: LG
},{
  headerName: 'Scheduler',
  headerTooltip: 'Scheduler per Unifier Project Details Information',
  field: 'scheduler',
  width: MD
}];

export default (showToggled: boolean): ColDef[] => {
  var cols = [...LeftColumns];
  if(showToggled){
    cols = [...cols,...ToggleableColumns];
  }
  cols = [...cols, ...RightColumns];
  return cols;
}

function currencyFormatter(params: ValueFormatterParams){
  return accounting.formatColumn([params.value]).join('');
}

const DateClassMap: {[prop in TDateSource]: string} = {
  CM: 'blue-text',
  FAST_CIP: 'black-text',
  PM: 'orange-text'
};

function dateComparator(date1: string, date2: string, nodeA: RowNode, nodeB: RowNode, isInverted: boolean): number {
  var date1Number = monthToComparableNumber(date1);
  var date2Number = monthToComparableNumber(date2);

  if (date1Number === null && date2Number === null) {
    return -1;
  }
  if(date1Number === null) {
    return isInverted ? -1 : 1;
  }
  if(date2Number === null) {
    return isInverted ? 1 : -1;
  }
  return date1Number - date2Number;
}

function monthToComparableNumber(date: string): number | null {
  if (date === undefined || date === null) {
    return null;
  }
  const d = new Date(date);
  // @ts-ignore
  if(d == 'Invalid Date'){
    return null;
  }
  return d.valueOf();
}