/**
 * Created by Kevin on 4/17/2017.
 */

import Axios, {Canceler} from 'axios';

export default Axios.create({
  baseURL: 'https://pcm.sandi.net/apiserver/api.rsc/',
  headers:{'x-cdata-authtoken': '9b2P7g8r0Q1a1j8Y2g1u'}
})

export const getCancelConfig = () => {
  const {token, cancel} = Axios.CancelToken.source();
  return {
    cancelToken: token,
    cancel
  }
};

export interface ICancellable<T> {
  request: T;
  cancel: Canceler;
}