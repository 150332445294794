import React from 'react';
import {Typography, TypographyProps} from '@material-ui/core';

const PrimaryHeader: React.FC<IPrimaryHeaderProps> = (props) => {
  const {children, ...rest} = props;
  return (
    <Typography variant="h6" {...rest}>{children}</Typography>
  );
};

export default PrimaryHeader;

interface IPrimaryHeaderProps extends Omit<TypographyProps, 'variant'> {
}